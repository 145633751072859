import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { StaticImage } from 'gatsby-plugin-image'
import DonationTracker from "../DonationTracker/DonationTracker";

const Donate = () => {
    return( 
        <div id="donate">
            <Container>
                <Row className="bgPanel">
                    <Col className="homeSupport">
                        <div style={{ fontSize: "1.5rem", paddingBottom: "10px" }}>Donation Goal</div>
                        <div className="tracker">
                                <div className="tracker-item"> 0 </div>
                                <div className="tracker-item2">
                                    <DonationTracker />
                                </div>
                                <div className="tracker-item"> $10000 </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button className="donateButton" > 
                            <h1 className="centerText"> <a href="https://streamlabscharity.com/@a-drop-of-delight/a-drop-of-delight-tf2-fundraiser" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}> DONATE HERE </a> </h1>
                        </Button>
                    </Col>
                </Row>
                <Row className="bgPanel">
                    <Col className="donateWhy">
                        <div className="donateTitle">
                            <h1> WHY DONATE? </h1> 
                        </div>
                        <hr/>
                        <div className="donateText">
                            Water is the beginning of the journey out of poverty... which leads to better health, increased prospects, and brighter futures. <br/>
                            Your donation will help the 1.6 million people and counting whose lives have been changed with safe, clean water! 
                            100% of all donations go to <a className="styledLink" href="https://www.justadrop.org/" target="_blank" rel="noopener noreferrer">Just a Drop</a>
                            &nbsp;to support their projects around the world in providing clean water through:
                            <div className="donateList">
                                <ul>
                                    <li> distribution pipelines </li>
                                    <li> submersible pumps </li>
                                    <li> water storage tanks </li>
                                </ul>
                                <ul>
                                    <li> sand filters </li>
                                    <li> latrines </li>
                                    <li> wells </li>
                                </ul>        
                            </div>
                        </div>
                        <div className="donateImage-container">
                            <StaticImage src="https://res.cloudinary.com/a-drop-of-delight/image/upload/v1630391952/goalsImages/2_p4fklo.jpg" className="donateImage" />
                            <StaticImage src="https://res.cloudinary.com/a-drop-of-delight/image/upload/v1630391952/goalsImages/7_ewlzmi.jpg" className="donateImage" />
                        </div>
                    </Col>
                </Row>
                <Row className="bgPanel">
                    <Col className="donatePerks">
                        <div>
                            <h1> DONATION PERKS </h1>    
                        </div> 
                        <hr/>
                        <div>
                            <StaticImage src="../../images/sweepImages/6.png" className="perksImage" />
                            <StaticImage src="../../images/sweepImages/7.png" className="perksImage" />
                            <StaticImage src="../../images/sweepImages/5.png" className="perksImage" />
                        </div>
                        <div>
                            <StaticImage src="../../images/sweepImages/4.png" className="perksImage" />
                            <StaticImage src="../../images/sweepImages/2.png" className="perksImage" />
                            <StaticImage src="../../images/sweepImages/1.png" className="perksImage" />
                        </div>
                        <div>
                            <StaticImage src="../../images/sweepImages/8.png" className="perksImage" />
                            <StaticImage src="../../images/sweepImages/3.png" className="perksImage" />
                            <StaticImage src="../../images/sweepImages/9.png" className="perksImage" />
                        </div>
                        <div className="giveawayText">
                            In order to show our appreciation, we are hosting a giveaway where you can have a chance
                            to win a cool, class specific Circling Hearts Unusual!
                        </div>
                        <div className="giveawayButton-group">
                            <Button className="giveawayButton"> 
                                <a href="https://scrap.tf/raffles/FTO8O7" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}> GIVEAWAY SET 1 </a>
                            </Button>
                            <Button className="giveawayButton"> 
                               <a href="https://scrap.tf/raffles/JGJUX4" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}> GIVEAWAY SET 2 </a>
                            </Button>
                        </div>
                        <div>
                            <StaticImage src="../../images/sweepImages/Scout.png" className="perksImage btmImage" />
                        </div>
                        <div className="giveawayText">
                            Since we are unable to add our unique badges to the game, we decided to make our own!
                            As a special treat for our top 100 donors, you will receive a fully unique and 
                            customized photo badge that’s signed by RocketManFran! <p/> In order to receive your Photo Badge, 
                            please make sure to include your steam trade offer link in the description box during your donation!
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Donate;