import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import Header from "../components/Header/Header"
import Donate from "../components/Donate/Donate"
import Footer  from "../components/Footer/Footer"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import {Helmet} from "react-helmet";

const DonatePage = () => {
	const { myBackground } = useStaticQuery(
		graphql`
			query {
				myBackground: file(relativePath: { eq: "background.jpg" }) {
				childImageSharp {
					gatsbyImageData(
					quality: 90, 
					webpOptions: {quality: 100}, 
					width: 2000
					)
				}
				}
			}
		`
	)

	const pluginImage = getImage(myBackground);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{'A Drop of Delight'}</title>
				<html lang={'en'} />
				<meta name="description" content={'A Fundraiser for Just a Drop'} />
			</Helmet>
			<BgImage image={pluginImage}>
				<Header />
				<Donate />
				<Footer />
			</BgImage>
		</>
	)
}
export default DonatePage